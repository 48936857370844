import React from "react"
import axios from "axios"

const defaultState = {
    areaList: []
}

const GlobalContext = React.createContext(defaultState)

class GlobalProvider extends React.Component {
  state = {
    areaList: [],
    gridView: false
  }

  // get area list
  getitems = async url => {
    try {
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
        }
      })
      // console.log("data", data)
      this.setState({ areaList: data })
    } catch (error) {
      console.error(error)
    }
  }

  toggleLayout = () => {
    let gridView = !this.state.gridView
    this.setState({ gridView })
  }

  componentDidMount() {
    // do api calls
    let url = process.env.GATSBY_STRAPI_SRC + "/areas_v1.json"
    this.getitems(url)
  }

  render() {
    const { children } = this.props
    return (
      <GlobalContext.Provider
        value={{
          ...this.state,
          toggleLayout: this.toggleLayout
        }}
      >
        {children}
      </GlobalContext.Provider>
    )
  }
}

export default GlobalContext

export { GlobalProvider }
