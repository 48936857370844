import fetch from 'cross-fetch';
import { ApolloClient, createHttpLink, InMemoryCache, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: `${process.env.GATSBY_STRAPI_SRC}/graphql`,
  fetch,
});
const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    }
  }
});

export const client = new ApolloClient({
  link: new HttpLink({
    uri: `${process.env.GATSBY_STRAPI_SRC}/graphql`,
    fetch,
  }),
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});
